import React, { useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import PdfButton from "../../ui/pdf-button";
import Button from "../../ui/button";
import SecondaryLinks from "../../ui/secondary-links";
import Gallery from "../../gallery";
import ApplyNow from "../../ui/apply-now-button";
import ScheduleTour from "../../ui/schedule-button";
import {
  Wrapper,
  MenuWrap,
  Item,
  Li,
  Overlay,
  Primary,
  CloseButton,
  PortalLink,
  Line,
  WLogo,
  Btns,
  Center,
} from "./index.styled";
import { useState } from "react";
import useWindowSize from "../../../helpers/useWindowSize";

const Menu = () => {
  const {
    menuContent: { content: links },
  } = useStaticQuery(graphql`
    query MenuQuery {
      menuContent {
        content {
          primary {
            title
            url
          }
        }
      }
    }
  `);
  const menuRef = useRef(null);
  const { isMobile } = useWindowSize();

  const onClose = () => {
    menuRef.current.classList.remove("open");
  };
  const [openGallery, setOpenGallery] = useState(false);
  const onGallery = () => {
    setOpenGallery(true);
  };

  return (
    <Wrapper id={"menu"} ref={menuRef}>
      <MenuWrap>
        <Line className={"top"}>
          <CloseButton onClick={onClose} />
          {/*  <PortalLink
            href="https://mapleterraceresidences.residentportal.com/auth"
            target={'_blank'}
          >
            Resident Portal
          </PortalLink> */}
        </Line>
        <Center>
          <Primary>
            {links.primary.map((item, index) => (
              <Li key={`primary-item-${index}`} onClick={onClose}>
                {item.url === "/gallery" ? (
                  <Item as="div" onClick={onGallery} index={index}>
                    {item.title}
                  </Item>
                ) : item.title === "Schedule a Tour" ? (
                  <Item index={index} to={"#"} className={"hytour-link"}>
                    {item.title}
                  </Item>
                ) : (
                  <Item to={item.url} index={index}>
                    {item.title}
                  </Item>
                )}
              </Li>
            ))}
          </Primary>
          {/* <WLogo /> */}
          <Btns>
            <Button
              title={"Resident Portal"}
              className={"black"}
              as={"a"}
              target={"_blank"}
              href="https://mapleterraceresidences.residentportal.com/auth"
            />
            <PdfButton />
          </Btns>
        </Center>
        <Line className="col">
          <Line className="row">
            {/*  <ScheduleTour className={'webHide black'} />
            <ApplyNow className={['webHide black'].join(' ')} /> */}
          </Line>
          <SecondaryLinks onClose={onClose} />
        </Line>
      </MenuWrap>
      <Overlay onClick={onClose} />
      <Gallery open={openGallery} onClose={() => setOpenGallery(false)} />
    </Wrapper>
  );
};

export default Menu;
