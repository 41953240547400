import React, { useEffect, useState } from "react";
import {
  Container,
  Title,
  CloseButton,
  Buttons,
  SpecialContent,
} from "./index.styled";
import Button from "../button/index";
import axios from "axios";
import "./newleasing.css";
import BlackDollarIcon from "images/dollar_white.svg";

const NewLeasing = ({
  isFirstLoad,
  isFirstLeasing,
  data,
  setIsFirstLeasing = () => {},
}) => {
  const [show, setShow] = useState(false);
  const [specialsData, setSpecialsData] = React.useState([]);
  const [fpSpecialList, setFpSpecialList] = React.useState([]);

  const onClose = () => {
    setShow(false);

    setTimeout(() => {
      setIsFirstLeasing(false);
    }, 1000);
  };

  const onScroll = () => {
    window.scrollY === 0 ? setShow(true) : setShow(false);
  };
  useEffect(() => {
    if (document) {
      document.addEventListener("scroll", onScroll);
    }
  }, []);

  useEffect(() => {
    if (isFirstLoad) {
      setTimeout(() => {
        setShow(true);
      }, 4000);
    } else if (isFirstLeasing) {
      setShow(true);
    }
  }, [isFirstLoad, isFirstLeasing]);

  React.useEffect(() => {
    fetchSpecialsData();
  }, []);

  const fetchSpecialsData = async () => {
    try {
      // const bearerToken = process.env.GATSBY_TOKEN;

      const response = await axios.get(
        `https://mapleterraceresidences.com/cms/api/specials`,
        {
          headers: {
            // Authorization: `Bearer ${bearerToken}`,
          },
        }
      );

      setSpecialsData(response?.data?.data[0]?.attributes?.propertySpecials);
      setFpSpecialList(
        response?.data?.data[0]?.attributes?.floorplanSpecials?.floorplans
          ?.floorplan
      );
    } catch (error) {
      console.error(
        `Error fetching data: ${
          error.response ? error.response.data : error.message
        }`
      );
    }
  };

  return (
    <>
      {specialsData && (
        <Container className={show && "show"}>
          <SpecialContent>
            <CloseButton onClick={onClose} />
            <Title>
              {fpSpecialList?.length > 0 && (
                <img
                  id="dollarIconToShow"
                  style={{ marginRight: 10 + "px" }}
                  src={BlackDollarIcon}
                  width="22px"
                  height="22px"
                  alt="special icon"
                />
              )}
              {specialsData?.description}
              <span>
                *Restrictions may apply. See leasing team for details.
              </span>
            </Title>
            <Buttons>
              {/* <ScheduleTour className={'white beige'} /> */}
              <Button
                to={"#"}
                title={"Schedule a Tour"}
                className={"white beige hytour-link"}
                onClick={onClose}
                El="a"
                // target={"_blank"}
              />
            </Buttons>
          </SpecialContent>
        </Container>
      )}
    </>
  );
};

export default NewLeasing;
