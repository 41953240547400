import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Transition, TransitionGroup } from "react-transition-group";
import { gsap, ScrollTrigger } from "helpers/gsap";
import useWindowSize from "helpers/useWindowSize";
import Header from "components/header";
import Menu from "../header/menu";
import Footer from "components/footer";
import ThemeProvider from "../../helpers/ThemeProvider";
import LegalProvider from "../../helpers/LegalProvider";
import LegalModal from "../legal";
import CookiesBanner from "../ui/cookies-banner";
import HeaderTransition from "../ui/header-transition";
import MainIntro from "../ui/main-intro";

import "helpers/fonts.css";
import GlobalStyle from "helpers/globalStyles";
import { LayoutWrapper, PageWrapper, ContentWrapper } from "./index.styled";

const LayoutContent = ({ children, location }) => {
  return (
    <TransitionGroup component={null}>
      <Transition key={location.pathname} timeout={{ enter: 1000, exit: 1000 }}>
        {(status) => (
          <div className={`page ${status}`}>
            <HeaderTransition status={status}>
              <LayoutWrapper>{children}</LayoutWrapper>
            </HeaderTransition>
          </div>
        )}
      </Transition>
    </TransitionGroup>
  );
};

const Layout = (props) => {
  const {
    children,
    location,
    pageContext: { layout },
  } = props;
  const refWrapper = React.useRef(null);
  const { isMobile } = useWindowSize();

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isFirstLeasing, setIsFirstLeasing] = useState(false);
  let scrollerSmoother;

  React.useLayoutEffect(() => {
    let gsapContext = gsap.context(() => {
      //pin
      gsap.utils.toArray(".pin").forEach(function (container) {
        ScrollTrigger.create({
          trigger: container,
          pinSpacing: false,
          pin: true,
          start: "bottom bottom",
          anticipatePin: 1,
        });
      });

      //fadeIn
      gsap.utils.toArray(".fadeIn").forEach(function (container) {
        ScrollTrigger.create({
          trigger: container,
          start: "top center+=20%",
          end: "+=1000%",
          toggleClass: {
            targets: container,
            className: "visible",
            toggle: "add",
          },
          // once: true,
        });
      });

      const mediaQueryAnimation = gsap.matchMedia();
      mediaQueryAnimation.add(`(min-width: 769px)`, () => {
        //parallax
        gsap.utils.toArray(".parallax").forEach(function (container) {
          // scrollerSmoother.effects(container.querySelector('.image'), {
          //   speed: '0.5s',
          // })
        });
      });
    }, refWrapper);

    return () => gsapContext.revert();
  }, [children]);

  useEffect(() => {
    if (scrollerSmoother && location.hash) {
      setTimeout(() => {
        // gsap.to(scrollerSmoother, {
        //   scrollTop: document.querySelector(`${location.hash}-section`)
        //     ?.offsetTop,
        //   duration: 0,
        // })
      }, 100);
    }
  }, [location.hash]);

  return (
    <ThemeProvider>
      <div id="hy-toursite" class="hy-overlay"></div>
      <input
        type="hidden"
        id="tour_path_input"
        value="/tours/mapleterraceresidences/site?dd=0&amp;popup=1"
      ></input>
      <LegalProvider>
        <div className="home-wrapper" ref={refWrapper}>
          {location.pathname === "/" && isFirstLoad && (
            <MainIntro setIsFirstLoad={setIsFirstLoad} />
          )}
          {location.pathname === "/legal/" && children}
          <Header
            isFirstLoad={isFirstLoad}
            layout={layout}
            location={location}
            setIsFirstLeasing={setIsFirstLeasing}
            isFirstLeasing={isFirstLeasing}
          />
          <Menu />

          <div className="home-content">
            <PageWrapper>
              <GlobalStyle />
              <ContentWrapper>
                <LayoutContent location={location}>{children}</LayoutContent>
              </ContentWrapper>
            </PageWrapper>
            <Footer layout={layout} />
          </div>
        </div>
        <CookiesBanner />
      </LegalProvider>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

export default Layout;
